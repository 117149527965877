import React from "react"
import { Main, Row, Text } from "../../../components/atomics"
import Page from "../../../components/Pages/Page"
import colors from "../../../colors"
import { useState } from "react"

const plans = [
  {
    label: "12 MESES",
    price: 63.99,
    data: [
      {
        key: "Cartão de Crédito ou Google/Apple Pay",
        description:
          "Clique no link abaixo, para pagar usando Cartão de Crédito, Apple Pay ou Google Pay. No campo email, informe o seu email cadastrado no app, para liberação do acesso premium.",
        titleButton: "Pagar com Cartão ou Google/Apple Pay",
        url:
          "https://buy.stripe.com/3csbKveWJ7y58Fi144?locale=pt-BR&prefilled_promo_code=FLYN20",
      },
      {
        key: "PIX",
        description:
          "Clique no link abaixo, para pagar com o PIX. Você pode pagar de 3 formas: Ler o QR Code, Copiar o código do QR Code, ou com a Chave Pix.",
        titleButton: "Pagar com PIX",
        url: "https://nubank.com.br/pagar/uwbk/Js1sFsDytj",
      },
    ],
  },
  // {
  //   label: "36 MESES",
  //   price: 127.99,
  //   data: [
  //     {
  //       key: "PIX, CARTÃO, MERCADO PAGO OU PAYPAL",
  //       description: 'Clique no link abaixo, para pagar com o Mercado Pago. Pelo mercado pago, você pode pagar com o seu saldo do mercado pago, paypal, pix ou cartão.',
  //       titleButton: 'Pagar com Mercado Pago',
  //       url: "http://mpago.la/1hrD91Q",
  //     },
  //   ],
  // },
  {
    label: "VITALÍCIO",
    price: 159.92,
    data: [
      {
        key: "Cartão de Crédito ou Google/Apple Pay",
        description:
          "Clique no link abaixo, para pagar usando Cartão de Crédito, Apple Pay ou Google Pay. No campo email, informe o seu email cadastrado no app, para liberação do acesso premium.",
        titleButton: "Pagar com Cartão ou Google/Apple Pay",
        url:
          "https://buy.stripe.com/cN29CncOBdWt08M7sJ?locale=pt-BR&prefilled_promo_code=FLYN20",
      },
    ],
  },
]

function PremiumPromotion() {
  const [plan, setPlan] = useState(plans[0])
  const [pay, setPay] = useState(plans[0].data[0])
  return (
    <Page title="Promoção" titlePage="Promoção" pageId="promotion">
      <Main>
        <div>
          <h1>🔥 PREMIUM COM 20% OFF!🔥</h1>

          <Text style={{ marginTop: 10 }}>
            Está na dúvida se você deve se tornar Premium?
            <br />
            <br />
            Pois temos um incentivo para você:{" "}
            <b>Assinatura Premium com 20% OFF!</b>
            <br />
            <br />
            Mas corre que esta oferta só estará disponível{" "}
            <b>por apenas 24 horas!</b>
            <br />
            <br />
          </Text>
          <Text bold style={{ marginTop: 10 }}>
            Plano Anual: de <s>79,99</s> por 63,99 <br />
            Plano Vitalício: de <s>179,99</s> por 143,99
          </Text>
          <br />
          <Text
            style={{ fontSize: "0.9rem", fontFamily: "Poppins-Bold", marginBottom: 10 }}
          >
            *Informações para adquirir o desconto:
          </Text>
          <Text style={{ fontSize: "0.9rem", lineHeight: "1.3rem" }}>
            {" "}
            ✔️ Escolha o plano que deseja assinar e o método de pagamento.
            Realize o pagamento conforme as intruções para o pagamento do método
            escolhido.
          </Text>
          <Text style={{ fontSize: "0.9rem", lineHeight: "1.3rem" }}>
            {" "}
            ✔️ Se tiver qualquer dúvida, entre em contato com a gente!{" "}
          </Text>

          <br />
          <h4>Selecione o plano:</h4>
          <Row>
            {plans.map(i => (
              <div
                onClick={() => {
                  setPlan(i)
                  setPay(i.data[0])
                }}
                style={{
                  padding: "10px 20px",
                  cursor: "pointer",
                  borderRadius: 10,
                  marginRight: 10,
                  marginTop: 5,
                  border:
                    i.label === plan.label
                      ? `3px solid ${colors.primary}`
                      : "1px solid #222",
                  color: i.label === plan.label ? colors.primary : undefined,
                }}
              >
                <h4>{i.label}</h4>
                <h3>{i.price}</h3>
              </div>
            ))}
          </Row>
          <h4 style={{ marginTop: 15 }}>Selecione o método de pagamento:</h4>
          {plan.data.map(i => (
            <div
              onClick={() => setPay(i)}
              style={{
                cursor: "pointer",
                padding: "5px 10px",
                borderRadius: 10,
                marginRight: 10,
                marginTop: 5,
                border:
                  i.key === pay.key
                    ? `2px solid ${colors.primary}`
                    : "1px solid #222",
                color: i.key === pay.key ? colors.primary : undefined,
              }}
            >
              <Text style={{ fontSize: "0.9rem", lineHeight: "1.1rem" }}>
                {i.key}
              </Text>
            </div>
          ))}

          <h4 style={{ marginTop: 15 }}>Instruções para o pagamento:</h4>
          <div style={{ marginTop: 5 }}>
            <Text>
              {pay.description && (
                <Text
                  style={{
                    fontSize: "0.9rem",
                    lineHeight: "1.1rem",
                    marginBottom: 10,
                  }}
                >
                  {pay.description}
                </Text>
              )}
              {pay.url && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      padding: "10px 30px",
                      backgroundColor: colors.green,
                      borderRadius: 22,
                      color: "#fff",
                      fontFamily: "Poppins-Bold",
                      border: "none",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <a
                      style={{
                        color: "#fff",
                        fontFamily: "Poppins-Bold",
                      }}
                      href={pay.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {pay.titleButton}
                    </a>
                  </div>
                </div>
              )}
              {pay.obs && <h6>{pay.obs}</h6>}
              {pay.key === "PIX" && (
                <Text
                  style={{
                    fontSize: "0.9rem",
                    lineHeight: "1.1rem",
                    marginTop: 10,
                  }}
                >
                  Após o pagamento, nos envie o comprovante por Email ou
                  WhatsApp e informe o seu email cadastrado no app para a
                  liberação dos recursos premium para a sua conta.
                </Text>
              )}
            </Text>
          </div>

          {pay.key === "PIX" && (
            <>
              <h4 style={{ marginTop: 15, marginBottom: 10 }}>
                Enviar comprovante via:
              </h4>
              <Text center style={{ fontSize: "0.95rem" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: 200,
                      padding: "8px",
                      backgroundColor: colors.primary,
                      borderRadius: 22,
                      color: "#fff",
                      fontFamily: "Poppins-Bold",
                      border: "none",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      style={{
                        color: "#fff",
                        fontFamily: "Poppins-Bold",
                      }}
                      href={
                        "https://api.whatsapp.com/send/?phone=5531999107753"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      WhatsApp
                    </a>
                  </div>
                  <Text style={{ fontSize: "0.9rem" }}>31 999107753</Text>
                  <div
                    style={{
                      marginTop: 5,
                      width: 200,
                      padding: "8px",
                      backgroundColor: colors.primary,
                      borderRadius: 22,
                      color: "#fff",
                      fontFamily: "Poppins-Bold",
                      border: "none",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <a
                      style={{
                        color: "#fff",
                        fontFamily: "Poppins-Bold",
                      }}
                      href={"mailto:productivity@appflynow.com"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Email
                    </a>
                  </div>
                  <Text style={{ fontSize: "0.9rem" }}>
                    productivity@appflynow.com
                  </Text>
                </div>
              </Text>
            </>
          )}
        </div>
      </Main>
    </Page>
  )
}

export default PremiumPromotion
